<template>
  <div>
    <div
      v-if="!loading"
    >
      <v-simple-table
        v-if="campaign.targetAudienceId && !hideTargetData"
        dense
        class="ta-table"
        fixed-header
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th width="35" />
              <th
                v-for="(expandedHeader, index) in taHeaders"
                :key="`${campaign.jobNumber}-${index}`"
                class="text-left"
                :width="expandedHeader.width"
              >
                {{ expandedHeader.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      :color="$_dashMixin_taStatusColor(campaign.targetAudience.status)"
                      left
                      v-on="on"
                    >
                      {{ $_dashMixin_getTAIcon(campaign.targetAudience.status) }}
                    </v-icon>
                  </template>
                  <span>{{ campaign.targetAudienceStatusDisplay || 'Not selected' }}</span>
                </v-tooltip>
              </td>
              <td>{{ campaign.targetAudience.jobNumber }}</td>
              <td>{{ campaign.targetAudience.poNumber }}</td>
              <td>{{ campaign.targetAudience.contactName }}</td>
              <td>{{ campaign.targetAudience.orderDateLocal | displayDateFormat }}</td>
              <td>
                <v-chip
                  :color="$_dashMixin_fileTypeColor(campaign.targetAudience.fileType)"
                  :dark="$_dashMixin_fileTypeColorDark(campaign.targetAudience.fileType)"
                >
                  {{ campaign.targetAudience.fileType }}
                </v-chip>
              </td>
              <td>
                <v-tooltip
                  v-if="!campaign.targetAudience.nthRecordSubCount"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      :color="$_dashMixin_countColor(campaign.targetAudience.count)"
                      dark
                      v-on="on"
                    >
                      {{ campaign.targetAudience.count | commaNumber }}
                    </v-chip>
                  </template>
                  <span>{{ $_dashMixin_countSendDaysLabel(campaign.targetAudience.count) }}</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      :color="$_dashMixin_countColor(campaign.targetAudience.nthRecordSubCount)"
                      dark
                      v-on="on"
                    >
                      {{ campaign.targetAudience.nthRecordSubCount | commaNumber }}/{{ campaign.targetAudience.count | commaNumber }}
                    </v-chip>
                  </template>
                  <span>{{ campaign.targetAudience.nthRecordSubCount | commaNumber }} Nth record of {{ campaign.targetAudience.count | commaNumber }} count. {{ $_dashMixin_countSendDaysLabel(campaign.targetAudience.nthRecordSubCount) }}</span>
                </v-tooltip>
              </td>
              <td>
                <template v-if="!campaign.readOnlyActions && showTAActions">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="mr-2"
                        :color="campaign.targetAudience.status === $_taStatus_Draft ? 'success' : 'info'"
                        @click="$_dashMixin_nowwEditLink(campaign.targetAudience)"
                        v-on="on"
                      >
                        {{ $_dashMixin_getTAIcon(campaign.targetAudience.status) }}
                      </v-icon>
                    </template>
                    <span>{{ campaign.targetAudience.status === $_taStatus_Draft ? 'Edit' : 'View' }} Target Audience</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="mr-2"
                        color="info"
                        @click="$_dashMixin_nowwCopyLink(campaign.targetAudience)"
                        v-on="on"
                      >
                        mdi-content-copy
                      </v-icon>
                    </template>
                    <span>Copy Target Audience</span>
                  </v-tooltip>
                </template>
                <template v-else-if="showTAActions && campaign.targetAudience.status === $_taStatus_Completed">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="mr-2"
                        color="info"
                        @click="$_dashMixin_nowwEditLink(campaign.targetAudience)"
                        v-on="on"
                      >
                        {{ $_dashMixin_getTAIcon(campaign.targetAudience.status) }}
                      </v-icon>
                    </template>
                    <span>View Target Audience</span>
                  </v-tooltip>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table
        dense
        class="mailings-table"
        fixed-header
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                <span>{{ campaign.mailings.length }}/{{ !campaign.campaignExpired ? campaign.numberOfBroadcasts : campaign.mailings.length }}</span>
              </th>
              <th
                v-for="(expandedHeader, index) in expandedHeaders"
                :key="`${campaign.jobNumber}-${index}`"
                class="text-left"
                :width="expandedHeader.width"
              >
                {{ expandedHeader.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(mail, index) in campaign.mailings"
              :key="mail.id"
              :class="{ 'highlighted': mail.id === currentMailingId }"
            >
              <td width="56">
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      :color="$_dashMixin_getMailingIconColor(mail)"
                      v-on="on"
                    >
                      {{ $_dashMixin_getMailingIcon(mail) }}
                    </v-icon>
                  </template>
                  <span>{{ mail.statusDisplay }}</span>
                </v-tooltip>
              </td>
              <td>{{ mail.name }}{{ mail.sequence > 1 && mail.resendTypeDisplay !== null ? ` (${mail.resendTypeDisplay})` : '' }}</td>
              <td v-if="mail.splits && mail.splits.length === 1">
                {{ mail.splits[0].subject }}
              </td>
              <td
                v-else
              >
                <v-tooltip
                  v-for="(split, i) in mail.splits"
                  :key="i"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-progress-linear
                      :style="`width: ${split.distribution}%`"
                      striped
                      height="20"
                      value="100"
                      :color="$_splitMixin_splitColors[i]"
                      v-on="on"
                    >
                      <template v-slot="{ value }">
                        <strong>{{ split.distribution }}%</strong>
                      </template>
                    </v-progress-linear>
                  </template>
                  <span>{{ split.subject || `Subject ${$_splitMixin_splitLetters[i]}` }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-progress-linear
                      :style="`width: ${mail.winningDistribution}%`"
                      striped
                      height="20"
                      buffer-value="100"
                      :color="$_splitMixin_winningColor"
                      v-on="on"
                    >
                      <template v-slot="{ value }">
                        <strong>{{ mail.winningDistribution }}%</strong>
                      </template>
                    </v-progress-linear>
                  </template>
                  <span>{{ $_splitMixin_winningCriteriaSelect.find(x => x.value === mail.winningCriteria) ? $_splitMixin_winningCriteriaSelect.find(x => x.value === mail.winningCriteria).text : 'TBD' }}</span>
                </v-tooltip>
              </td>
              <td>
                <v-btn
                  v-for="(split, i) in mail.splits"
                  :key="i"
                  icon
                  :color="$_splitMixin_splitColors[i]"
                  @click="getSplitHtmlDialog(split.id, mail.id)"
                >
                  <span
                    class="title"
                  >
                    {{ $_splitMixin_indexToChar(i) }}
                  </span>
                </v-btn>
              </td>
              <td>
                <v-chip
                  v-if="mail.scheduleDate && mail.isScheduledStatus"
                  :color="$_dashMixin_scheduleDateColor($_dashMixin_dateToLocal(mail.scheduleDate), mail, campaign)"
                  dark
                >
                  {{ $_dashMixin_dateToLocal(mail.scheduleDate) | displayDateTimeFormat }}
                </v-chip>
                {{ mail.winningDistribution > 0 && mail.winningScheduleDate && mail.isScheduledStatus ? ' / ' : '' }}
                <v-chip
                  v-if="mail.winningDistribution > 0 && mail.winningScheduleDate && mail.isScheduledStatus"
                  :color="$_dashMixin_scheduleDateColorWinner($_dashMixin_dateToLocal(mail.winningScheduleDate), mail, campaign)"
                  dark
                >
                  {{ $_dashMixin_dateToLocal(mail.winningScheduleDate) | displayDateTimeFormat }}
                </v-chip>
              </td>
              <td>
                <template v-if="!campaign.readOnlyActions">
                  <template
                    v-if="!mail.isScheduledStatus"
                  >
                    <v-tooltip
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          :disabled="campaign.isCanceled || mail.status === $_mailingItemStatus_Sent || mail.status === $_mailingItemStatus_Sending || mail.id === currentMailingId"
                          class="mr-2"
                          color="success"
                          @click="$_dashMixin_continueMailing(mail)"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Continue Mailing</span>
                    </v-tooltip>
                    <v-menu
                      v-if="mail.sequence > 1"
                      right
                      x-offset
                      origin="right"
                      transition="slide-x-transition"
                    >
                      <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip
                          top
                        >
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-icon
                              :disabled="campaign.isCanceled"
                              class="mr-2"
                              color="error"
                              v-on="{ ...onMenu, ...onTooltip }"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span>Delete Resend</span>
                        </v-tooltip>
                      </template>
                      <v-card>
                        <v-card-text class="pa-5">
                          <span>Are you sure you want to delete this resend?</span>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="tertiary"
                            :disabled="isLoading()"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="error"
                            :loading="isLoading()"
                            @click="deleteResend(campaign, mail, index)"
                          >
                            Yes
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </template>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :disabled="!campaign.canAddMailings"
                        class="mr-2"
                        color="info"
                        @click="$_dashMixin_copyMailing(mail)"
                        v-on="on"
                      >
                        mdi-content-copy
                      </v-icon>
                    </template>
                    <span>Add Copy of Mailing</span>
                  </v-tooltip>
                  <template v-if="mail.isScheduledStatus">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          :disabled="campaign.isCanceled || mail.status === $_mailingItemStatus_Sent || mail.status === $_mailingItemStatus_Sending"
                          class="mr-2"
                          color="warning"
                          @click="$_dashMixin_unscheduleMailing(mail)"
                          v-on="on"
                        >
                          mdi-calendar-edit
                        </v-icon>
                      </template>
                      <span>Unschedule Mailing</span>
                    </v-tooltip>
                  </template>
                </template>
                <v-tooltip
                  v-if="mail.isScheduledStatus && ($_global_hasRouteClaims('MailingResultsDashboard') || (mail.status === $_mailingItemStatus_Sent && $_global_hasRouteClaims('SingleMailingReport')))"
                  top
                >
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on: onMenu, attrs }">
                        <v-icon
                          class="mr-2"
                          color="info"
                          v-bind="attrs"
                          v-on="{ ...onTooltip, ...onMenu }"
                        >
                          mdi-chart-box
                        </v-icon>
                      </template>
                      <v-list v-if="mail.status === $_mailingItemStatus_Sent || mail.status === $_mailingItemStatus_Sending && $_global_hasRouteClaims('SingleMailingReport')">
                        <!-- Removing Real Time Dashboard -->
                        <!-- <v-list-item v-if="$_global_hasRouteClaims('MailingResultsDashboard')">
                          <v-list-item-title>
                            <router-link :to="{ name: 'MailingResultsDashboard', params: { campaignId: campaign.campaignId, mailingItemId: mail.id } }">
                              Real Time Results
                            </router-link>
                          </v-list-item-title>
                        </v-list-item> -->
                        <v-list-item>
                          <v-list-item-title>
                            <router-link :to="{ name: 'SingleMailingReport', params: { campaignId: campaign.campaignId, mailingItemId: mail.id } }">
                              Single Mailing Report
                            </router-link>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  <span>Reporting</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <v-skeleton-loader
      v-else
      type="table"
    />
    <v-dialog
      v-model="htmlDialog"
      scrollable
      max-width="1400"
    >
      <v-card>
        <v-card-text
          class="pa-5"
          style="max-height: 800px"
        >
          <MailingPreview
            :mailing-item-id="mailingItemId"
            :split-id="splitId"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="tertiary"
            @click="closeHtmlDialog()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dashboardMixin } from '@/shared/mixins/mailing'
import { enumsMixin } from '@/shared/mixins/general'
import moment from 'moment'
import { azBlobService } from '@/shared/services'
import claims from '@/shared/models/auth/claims'

export default {
  name: 'CampaignDashboardExpanded',

  components: {
    MailingPreview: () => import('@/views/pages/components/mailing/MailingPreview')
  },

  mixins: [dashboardMixin, enumsMixin],

  props: {
    campaign: {
      type: Object,
      default: null
    },
    currentMailingId: {
      type: String,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideTargetData: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data () {
    return {
      htmlDialog: false,
      splitId: '',
      mailingItemId: '',
      taHeaders: [
        {
          text: 'Job',
          value: 'jobNumber',
          width: '150'
        },
        {
          text: 'PO #',
          value: 'poNumber',
          width: '300'
        },
        {
          text: 'Contact',
          value: 'contactName',
          width: '200'
        },
        {
          text: 'Order Date',
          value: 'orderDateLocal',
          width: '100'
        },
        {
          text: 'File Type',
          value: 'fileType',
          width: '100'
        },
        {
          text: 'Count',
          value: 'count',
          width: '150'
        },
        {
          text: 'Actions',
          value: 'actions',
          width: '200',
          sortable: false,
          filterable: false
        }
      ],
      expandedHeaders: [
        {
          text: 'Mailing',
          value: 'name',
          width: '150'
        },
        {
          text: 'Subject',
          value: 'splits',
          width: '400'
        },
        {
          text: 'Details',
          value: 'azHtmlContentLink',
          width: '175'
        },
        {
          text: 'Schedule Date(s)',
          value: 'scheduleDate',
          width: '350'
        },
        {
          text: 'Actions',
          value: 'actions',
          width: '200'
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('mailing', ['getMailingItemId']),
    ...mapGetters('auth', ['hasClaimKV']),
    showTAActions () {
      return this.hasClaimKV(claims.ITA_DSC)
    }
  },

  methods: {
    async getSplitHtmlDialog (id, mailingItemId) {
      this.htmlDialog = true
      this.splitId = id
      this.mailingItemId = mailingItemId
    },
    closeHtmlDialog () {
      this.htmlDialog = false
      this.splitId = ''
      this.mailingItemId = ''
    },
    async deleteResend (campaign, mail, index) {
      let campaignModel = await this.$_dashMixin_deleteResend(campaign, mail, index)
      this.$emit('updateCampaignMailings', campaignModel)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table.theme--light {
  background-color: #fafafa;
}
.v-data-table.theme--dark {
  background-color: #424242;
}
.v-data-table {
  border-left-width: 5px;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-left-style: solid;
  border-top-style: solid;
  border-top-color: #E0E0E0;
  border-top-width: 3px;
}

.mailings-table {
  border-left-color: #ffc107;
}

.ta-table {
  border-left-color: #2196F3;
}

.highlighted {
  // border: 3px solid var(--v-success-base) !important;
  background-color: #eeeeee;// var(--v-tertiary-lighten5) !important;
}

.v-progress-linear {
  display: inline-block;
  vertical-align: middle;
}
</style>
