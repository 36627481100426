var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading
        ? _c(
            "div",
            [
              _vm.campaign.targetAudienceId && !_vm.hideTargetData
                ? _c("v-simple-table", {
                    staticClass: "ta-table",
                    attrs: { dense: "", "fixed-header": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c("thead", [
                                _c(
                                  "tr",
                                  [
                                    _c("th", { attrs: { width: "35" } }),
                                    _vm._l(_vm.taHeaders, function(
                                      expandedHeader,
                                      index
                                    ) {
                                      return _c(
                                        "th",
                                        {
                                          key:
                                            _vm.campaign.jobNumber +
                                            "-" +
                                            index,
                                          staticClass: "text-left",
                                          attrs: { width: expandedHeader.width }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(expandedHeader.text) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]),
                              _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: _vm.$_dashMixin_taStatusColor(
                                                              _vm.campaign
                                                                .targetAudience
                                                                .status
                                                            ),
                                                            left: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$_dashMixin_getTAIcon(
                                                                _vm.campaign
                                                                  .targetAudience
                                                                  .status
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            266786561
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.campaign
                                                  .targetAudienceStatusDisplay ||
                                                  "Not selected"
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.campaign.targetAudience.jobNumber
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.campaign.targetAudience.poNumber
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.campaign.targetAudience.contactName
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("displayDateFormat")(
                                          _vm.campaign.targetAudience
                                            .orderDateLocal
                                        )
                                      )
                                    )
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            color: _vm.$_dashMixin_fileTypeColor(
                                              _vm.campaign.targetAudience
                                                .fileType
                                            ),
                                            dark: _vm.$_dashMixin_fileTypeColorDark(
                                              _vm.campaign.targetAudience
                                                .fileType
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.campaign.targetAudience
                                                  .fileType
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      !_vm.campaign.targetAudience
                                        .nthRecordSubCount
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-chip",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                color: _vm.$_dashMixin_countColor(
                                                                  _vm.campaign
                                                                    .targetAudience
                                                                    .count
                                                                ),
                                                                dark: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.campaign
                                                                      .targetAudience
                                                                      .count
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1865721666
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$_dashMixin_countSendDaysLabel(
                                                      _vm.campaign
                                                        .targetAudience.count
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-chip",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                color: _vm.$_dashMixin_countColor(
                                                                  _vm.campaign
                                                                    .targetAudience
                                                                    .nthRecordSubCount
                                                                ),
                                                                dark: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.campaign
                                                                      .targetAudience
                                                                      .nthRecordSubCount
                                                                  )
                                                                ) +
                                                                "/" +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.campaign
                                                                      .targetAudience
                                                                      .count
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3440508751
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("commaNumber")(
                                                      _vm.campaign
                                                        .targetAudience
                                                        .nthRecordSubCount
                                                    )
                                                  ) +
                                                    " Nth record of " +
                                                    _vm._s(
                                                      _vm._f("commaNumber")(
                                                        _vm.campaign
                                                          .targetAudience.count
                                                      )
                                                    ) +
                                                    " count. " +
                                                    _vm._s(
                                                      _vm.$_dashMixin_countSendDaysLabel(
                                                        _vm.campaign
                                                          .targetAudience
                                                          .nthRecordSubCount
                                                      )
                                                    )
                                                )
                                              ])
                                            ]
                                          )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      !_vm.campaign.readOnlyActions &&
                                      _vm.showTAActions
                                        ? [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  color:
                                                                    _vm.campaign
                                                                      .targetAudience
                                                                      .status ===
                                                                    _vm.$_taStatus_Draft
                                                                      ? "success"
                                                                      : "info"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$_dashMixin_nowwEditLink(
                                                                      _vm
                                                                        .campaign
                                                                        .targetAudience
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm.$_dashMixin_getTAIcon(
                                                                      _vm
                                                                        .campaign
                                                                        .targetAudience
                                                                        .status
                                                                    )
                                                                  ) +
                                                                  "\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1240705103
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.campaign
                                                        .targetAudience
                                                        .status ===
                                                        _vm.$_taStatus_Draft
                                                        ? "Edit"
                                                        : "View"
                                                    ) + " Target Audience"
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  color: "info"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$_dashMixin_nowwCopyLink(
                                                                      _vm
                                                                        .campaign
                                                                        .targetAudience
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                      mdi-content-copy\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3129483332
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Copy Target Audience")
                                                ])
                                              ]
                                            )
                                          ]
                                        : _vm.showTAActions &&
                                          _vm.campaign.targetAudience.status ===
                                            _vm.$_taStatus_Completed
                                        ? [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  color: "info"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$_dashMixin_nowwEditLink(
                                                                      _vm
                                                                        .campaign
                                                                        .targetAudience
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm.$_dashMixin_getTAIcon(
                                                                      _vm
                                                                        .campaign
                                                                        .targetAudience
                                                                        .status
                                                                    )
                                                                  ) +
                                                                  "\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2938032315
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("View Target Audience")
                                                ])
                                              ]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      320805574
                    )
                  })
                : _vm._e(),
              _c("v-simple-table", {
                staticClass: "mailings-table",
                attrs: { dense: "", "fixed-header": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _c("th", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.campaign.mailings.length) +
                                        "/" +
                                        _vm._s(
                                          !_vm.campaign.campaignExpired
                                            ? _vm.campaign.numberOfBroadcasts
                                            : _vm.campaign.mailings.length
                                        )
                                    )
                                  ])
                                ]),
                                _vm._l(_vm.expandedHeaders, function(
                                  expandedHeader,
                                  index
                                ) {
                                  return _c(
                                    "th",
                                    {
                                      key: _vm.campaign.jobNumber + "-" + index,
                                      staticClass: "text-left",
                                      attrs: { width: expandedHeader.width }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(expandedHeader.text) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.campaign.mailings, function(
                              mail,
                              index
                            ) {
                              return _c(
                                "tr",
                                {
                                  key: mail.id,
                                  class: {
                                    highlighted:
                                      mail.id === _vm.currentMailingId
                                  }
                                },
                                [
                                  _c(
                                    "td",
                                    { attrs: { width: "56" } },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: _vm.$_dashMixin_getMailingIconColor(
                                                              mail
                                                            )
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$_dashMixin_getMailingIcon(
                                                                mail
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(mail.statusDisplay))
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(mail.name) +
                                        _vm._s(
                                          mail.sequence > 1 &&
                                            mail.resendTypeDisplay !== null
                                            ? " (" +
                                                mail.resendTypeDisplay +
                                                ")"
                                            : ""
                                        )
                                    )
                                  ]),
                                  mail.splits && mail.splits.length === 1
                                    ? _c("td", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(mail.splits[0].subject) +
                                            "\n            "
                                        )
                                      ])
                                    : _c(
                                        "td",
                                        [
                                          _vm._l(mail.splits, function(
                                            split,
                                            i
                                          ) {
                                            return _c(
                                              "v-tooltip",
                                              {
                                                key: i,
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-progress-linear",
                                                            _vm._g(
                                                              {
                                                                style:
                                                                  "width: " +
                                                                  split.distribution +
                                                                  "%",
                                                                attrs: {
                                                                  striped: "",
                                                                  height: "20",
                                                                  value: "100",
                                                                  color:
                                                                    _vm
                                                                      .$_splitMixin_splitColors[
                                                                      i
                                                                    ]
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var value =
                                                                          ref.value
                                                                        return [
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  split.distribution
                                                                                ) +
                                                                                  "%"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      split.subject ||
                                                        "Subject " +
                                                          _vm
                                                            .$_splitMixin_splitLetters[
                                                            i
                                                          ]
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          }),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-progress-linear",
                                                          _vm._g(
                                                            {
                                                              style:
                                                                "width: " +
                                                                mail.winningDistribution +
                                                                "%",
                                                              attrs: {
                                                                striped: "",
                                                                height: "20",
                                                                "buffer-value":
                                                                  "100",
                                                                color:
                                                                  _vm.$_splitMixin_winningColor
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var value =
                                                                        ref.value
                                                                      return [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                mail.winningDistribution
                                                                              ) +
                                                                                "%"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$_splitMixin_winningCriteriaSelect.find(
                                                      function(x) {
                                                        return (
                                                          x.value ===
                                                          mail.winningCriteria
                                                        )
                                                      }
                                                    )
                                                      ? _vm.$_splitMixin_winningCriteriaSelect.find(
                                                          function(x) {
                                                            return (
                                                              x.value ===
                                                              mail.winningCriteria
                                                            )
                                                          }
                                                        ).text
                                                      : "TBD"
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        2
                                      ),
                                  _c(
                                    "td",
                                    _vm._l(mail.splits, function(split, i) {
                                      return _c(
                                        "v-btn",
                                        {
                                          key: i,
                                          attrs: {
                                            icon: "",
                                            color:
                                              _vm.$_splitMixin_splitColors[i]
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.getSplitHtmlDialog(
                                                split.id,
                                                mail.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", { staticClass: "title" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$_splitMixin_indexToChar(
                                                    i
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      mail.scheduleDate &&
                                      mail.isScheduledStatus
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color: _vm.$_dashMixin_scheduleDateColor(
                                                  _vm.$_dashMixin_dateToLocal(
                                                    mail.scheduleDate
                                                  ),
                                                  mail,
                                                  _vm.campaign
                                                ),
                                                dark: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "displayDateTimeFormat"
                                                    )(
                                                      _vm.$_dashMixin_dateToLocal(
                                                        mail.scheduleDate
                                                      )
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            mail.winningDistribution > 0 &&
                                              mail.winningScheduleDate &&
                                              mail.isScheduledStatus
                                              ? " / "
                                              : ""
                                          ) +
                                          "\n              "
                                      ),
                                      mail.winningDistribution > 0 &&
                                      mail.winningScheduleDate &&
                                      mail.isScheduledStatus
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color: _vm.$_dashMixin_scheduleDateColorWinner(
                                                  _vm.$_dashMixin_dateToLocal(
                                                    mail.winningScheduleDate
                                                  ),
                                                  mail,
                                                  _vm.campaign
                                                ),
                                                dark: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "displayDateTimeFormat"
                                                    )(
                                                      _vm.$_dashMixin_dateToLocal(
                                                        mail.winningScheduleDate
                                                      )
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      !_vm.campaign.readOnlyActions
                                        ? [
                                            !mail.isScheduledStatus
                                              ? [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm
                                                                            .campaign
                                                                            .isCanceled ||
                                                                          mail.status ===
                                                                            _vm.$_mailingItemStatus_Sent ||
                                                                          mail.status ===
                                                                            _vm.$_mailingItemStatus_Sending ||
                                                                          mail.id ===
                                                                            _vm.currentMailingId,
                                                                        color:
                                                                          "success"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.$_dashMixin_continueMailing(
                                                                            mail
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        mdi-pencil\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Continue Mailing"
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  mail.sequence > 1
                                                    ? _c(
                                                        "v-menu",
                                                        {
                                                          attrs: {
                                                            right: "",
                                                            "x-offset": "",
                                                            origin: "right",
                                                            transition:
                                                              "slide-x-transition"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var onMenu =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          top:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var onTooltip =
                                                                                  ref.on
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-2",
                                                                                        attrs: {
                                                                                          disabled:
                                                                                            _vm
                                                                                              .campaign
                                                                                              .isCanceled,
                                                                                          color:
                                                                                            "error"
                                                                                        }
                                                                                      },
                                                                                      Object.assign(
                                                                                        {},
                                                                                        onMenu,
                                                                                        onTooltip
                                                                                      )
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                            mdi-close\n                          "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Delete Resend"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            [
                                                              _c(
                                                                "v-card-text",
                                                                {
                                                                  staticClass:
                                                                    "pa-5"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Are you sure you want to delete this resend?"
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("v-divider"),
                                                              _c(
                                                                "v-card-actions",
                                                                [
                                                                  _c(
                                                                    "v-spacer"
                                                                  ),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "tertiary",
                                                                        disabled: _vm.isLoading()
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          Cancel\n                        "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "error",
                                                                        loading: _vm.isLoading()
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteResend(
                                                                            _vm.campaign,
                                                                            mail,
                                                                            index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          Yes\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              : _vm._e(),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  disabled: !_vm
                                                                    .campaign
                                                                    .canAddMailings,
                                                                  color: "info"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$_dashMixin_copyMailing(
                                                                      mail
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                      mdi-content-copy\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Add Copy of Mailing")
                                                ])
                                              ]
                                            ),
                                            mail.isScheduledStatus
                                              ? [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm
                                                                            .campaign
                                                                            .isCanceled ||
                                                                          mail.status ===
                                                                            _vm.$_mailingItemStatus_Sent ||
                                                                          mail.status ===
                                                                            _vm.$_mailingItemStatus_Sending,
                                                                        color:
                                                                          "warning"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.$_dashMixin_unscheduleMailing(
                                                                            mail
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        mdi-calendar-edit\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Unschedule Mailing"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      mail.isScheduledStatus &&
                                      (_vm.$_global_hasRouteClaims(
                                        "MailingResultsDashboard"
                                      ) ||
                                        (mail.status ===
                                          _vm.$_mailingItemStatus_Sent &&
                                          _vm.$_global_hasRouteClaims(
                                            "SingleMailingReport"
                                          )))
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var onTooltip = ref.on
                                                      return [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              "offset-y": ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var onMenu =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-2",
                                                                              attrs: {
                                                                                color:
                                                                                  "info"
                                                                              }
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          Object.assign(
                                                                            {},
                                                                            onTooltip,
                                                                            onMenu
                                                                          )
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        mdi-chart-box\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            mail.status ===
                                                              _vm.$_mailingItemStatus_Sent ||
                                                            (mail.status ===
                                                              _vm.$_mailingItemStatus_Sending &&
                                                              _vm.$_global_hasRouteClaims(
                                                                "SingleMailingReport"
                                                              ))
                                                              ? _c(
                                                                  "v-list",
                                                                  [
                                                                    _c(
                                                                      "v-list-item",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          [
                                                                            _c(
                                                                              "router-link",
                                                                              {
                                                                                attrs: {
                                                                                  to: {
                                                                                    name:
                                                                                      "SingleMailingReport",
                                                                                    params: {
                                                                                      campaignId:
                                                                                        _vm
                                                                                          .campaign
                                                                                          .campaignId,
                                                                                      mailingItemId:
                                                                                        mail.id
                                                                                    }
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            Single Mailing Report\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Reporting")])]
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  248901077
                )
              })
            ],
            1
          )
        : _c("v-skeleton-loader", { attrs: { type: "table" } }),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", "max-width": "1400" },
          model: {
            value: _vm.htmlDialog,
            callback: function($$v) {
              _vm.htmlDialog = $$v
            },
            expression: "htmlDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "pa-5", staticStyle: { "max-height": "800px" } },
                [
                  _c("MailingPreview", {
                    attrs: {
                      "mailing-item-id": _vm.mailingItemId,
                      "split-id": _vm.splitId
                    }
                  })
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "tertiary" },
                      on: {
                        click: function($event) {
                          return _vm.closeHtmlDialog()
                        }
                      }
                    },
                    [_vm._v("\n          Close\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }